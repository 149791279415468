import {Pages} from "./Pages";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import React from "react";
import {Utils} from "../Utils";

export class Groups_cancelAdd extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers(params);

        let $dateStart = $("#date_start"),
            $dateEnd = $("#date_end"),
            dateFormat = app.config.DEFAULT_DATE_FORMAT,
            dateMin = "",
            dateMax = "",
            from = $dateStart
                .datepicker({
                    dateFormat: dateFormat,
                    defaultDate: "0",
                    changeMonth: true,
                    numberOfMonths: 3,
                    beforeShow: Utils.correctInputPosition,
                })
                .on("change", function () {
                    to.datepicker("option", "minDate", getDate(this));
                }),
            to = $dateEnd.datepicker({
                dateFormat: dateFormat,
                defaultDate: "0",
                changeMonth: true,
                numberOfMonths: 3,
                beforeShow: Utils.correctInputPosition,
            });

        //testGetDate();

        function getDate(element) {
            try {
                return $.datepicker.parseDate(dateFormat, element.value);
            } catch (error) {
                console.log(error + " in Groups_cancelAdd. Подробнее в логах JS!");
                return null;
            }
        }

        var $period = $("#period"),
            $endDateContainer = $("#end_date_container"),
            $labelStart = $("label[for='date_start']"),
            $reason = $("#reason");
        $period.on("change", function (e) {
            $dateEnd.val("");
            if (this.checked) {
                $endDateContainer.show();
                $labelStart.html("Дата начала");
            } else {
                $endDateContainer.hide();
                $labelStart.html("Дата");
                $dateEnd.val($dateStart.val());
            }
        });

        //валидация (совсем небольшая)
        $("#submit_button").on("click touchstart", function (e) {
            if ($period.prop("checked") && $dateEnd.val() === "") {
                alert("Если выбран ввод даты за период, то необходимо заполнить дату окончания периода");
                return false;
            } else if ($dateStart.val() === "") {
                alert("Не установлена дата начала периода");
                return false;
            } else if ($reason.val() === "") {
                alert("Не заполнена причина отмены (переноса)");
                return false;
            }
            //если не выбран период и дата окончания пустая - нужно поставить туда дату начала
            if (!$period.prop("checked") && $dateEnd.val() === "") {
                $dateEnd.val($dateStart.val());
            }
        });

        function testGetDate() {
            let arDate = ["12.01.2019", "1.1.19", "", " "];
            arDate.forEach((value) => {
                getDate({value: value});
            })
        }

    }

}